import { Link } from "react-router-dom";
import { Topic } from "shared/components/topic.component";
import { Blog } from "shared/services/blog.service";
import "./blog-banner.style.css";

interface Props {
    blog: Blog;
    index: number;
}

export function BlogBanner(props: Props) {
    return <Topic title={props.blog.date.toLocaleDateString()} height="100%">
                <div className="flexVertical flexJustifySpaceBetween height100pr blog-banner padding10px">
                    {props.blog.draft && <h1 className="draft">Draft!</h1>}
                    <h1>{props.blog.title}</h1>
                    {props.blog.thumbnail && <div className="flexHorizontal flexJustifyCenter mb10px">
                        <img 
                            className="thumbnail"
                            src={props.blog.thumbnail} 
                            alt={props.blog.title+"'s thumbnail"}
                        ></img>
                    </div>}                   
                    <p>{props.blog.shortText}</p>     
                    {props.blog.markDown && <Link to={`./entry?id=${props.index}`} className="linkOnBlack blog-banner-read-more-link">Read more</Link>}               
                </div>               
            </Topic>
}