import { useState } from "react";
import { games } from "../../../shared/services/game.service"
import { Topic } from "shared/components/topic.component";
import "./topic.style.css";
import { HorizontalSelector } from "./horizontal.selector.component";

export function GameTopic() {
    const [actualGame, setActualGame] = useState(0);
    return <Topic title="Games" titleLink="/games">
            <div className="flexVertical height100pr flexJustifySpaceBetween topicContentContainer">            
                <div>
                    <div className="flexHorizontal flexJustifyCenter mb10px">
                        <img 
                            className="thumbnail"
                            src={games[actualGame].thumbnail} 
                            alt={games[actualGame].title+"'s thumbnail"}
                        ></img>
                    </div>    
                    <div className="topicContent">
                        <h1 className="textAllignCenter">{games[actualGame].title}</h1>
                        {games[actualGame].subtitle && <h3 className="textAllignCenter">{games[actualGame].subtitle}</h3>}
                    </div>                    
                </div>
                             
            </div>
            <HorizontalSelector objects={games} setActualObject={setActualGame} />   
        </Topic>
}
