import ReactMarkdown from "react-markdown";
import { Card } from "shared/components/card.component";
import { useQuery } from "shared/hooks/use-query";
import { compileBlogs, useBlogs } from "shared/services/blog.service";
import remarkGfm from 'remark-gfm'
import "./blog-entry-view.style.css";
import { Link } from "react-router-dom";

export function BlogEntry() {
    const blogIdQueryParameter = useQuery().get('id');
    const blogID = blogIdQueryParameter? parseInt(blogIdQueryParameter): -1;
    const blog = compileBlogs(useBlogs(), (a, b) => b.date.getTime() - a.date.getTime())[blogID];
    return <div className="padding20px">
            <Card>
                <div className="flexHorizontal filler-horizontal">
                    {blog && <ReactMarkdown className="markdown" children={blog.markDown ? blog.markDown: 'no markdown present...'} remarkPlugins={[remarkGfm]}></ReactMarkdown>}
                </div>                
            </Card>
            <Link className="linkOnBlack backToBlogLink" to="/blog">Back to Blogs</Link>            
        </div>
}