import { Environements, useEnvironement } from "shared/hooks/use-environement";
import { NavLink } from "./navlink.component";
import burgerIcon from "assets/icons/menu-icon.svg";
import "./header.style.css";
import { useState } from "react";

interface Props {
    hideHomeLink?: boolean;
}

export function Header({hideHomeLink}: Props) {
    const [environement] = useEnvironement();
    const [dropdownVisible, setDropDownVisible] = useState(false);

    const toggleDropDown = (): void => {
        setDropDownVisible(!dropdownVisible);
    }

    if (environement === Environements.desktop) {
        return <header className="header flexVertical flexJustifyCenter">
            <nav className="marginX20px flexHorizontal flexGap30 flexAllignCenter">
                {!hideHomeLink ? <NavLink className="headerLink" to="/" name="Home"></NavLink> : <></>}
                <div className="filler-horizontal"></div>
                <NavLink className="headerLink" to="/about-me" name="Saturn91"></NavLink>
                <NavLink className="headerLink" to="/games" name="Games"></NavLink>
                <NavLink className="headerLink" to="/web" name="Web"></NavLink>
                <NavLink className="headerLink" to="/blog" name="Blog"></NavLink>
            </nav>
        </header>;
    }

    return <header>
            <nav className="height80">
                <button onClick={toggleDropDown} className="menuIcon">
                    <img src={burgerIcon} height="50px" alt="menu-button" />
                </button>            
                <div className={`dropdown flexVertical ${dropdownVisible ? {} : 'dropdown-hidden'}`} onClick={toggleDropDown}>
                    <NavLink to="/" name="Home"></NavLink>
                    <div className="filler-horizontal"></div>
                    <NavLink to="/about-me" name="Saturn91"></NavLink>
                    <NavLink to="/games" name="Games"></NavLink>
                    <NavLink to="/web" name="Web"></NavLink>
                    <NavLink to="/blog" name="Blog"></NavLink>
                </div>
            </nav>
        </header>;
}