import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface CompiledBlog {
    title: string;
    shortText: string;
    markDown?: string;
    thumbnail?:string;
    date: string;
    draft?: boolean;
}

export interface BlogType {
  blogs: CompiledBlog[];
}

const initialState: BlogType = {
    blogs: [],
}; 

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    addLoadedBlog: (state, action: PayloadAction<CompiledBlog>) => {
        state.blogs = [...state.blogs, action.payload];
    },
  },
})

// Action creators are generated for each case reducer function
export const { addLoadedBlog } = blogSlice.actions;

export default blogSlice.reducer;
