import { useEffect, useState } from "react";

export enum Environements {
    mobile,
    desktop
}

export function useEnvironement(): [Environements, number] {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    if (width <= 900) return [Environements.mobile, width];
    return [Environements.desktop, width];
}