import { BlogEntry } from "blogModule/components/blog-entry-view.component";
import { Route, Routes } from "react-router-dom";
import { AboutMe } from "../about-me/about-me.module";
import { Blog } from "../blogModule/blog.module";
import { Games } from "../gamesModule/games.module";
import { Home } from "../homeModule/home.module";
import { Play } from "../playModule/play.module";
import { Web } from "../webModule/web.module";
import { PageNotFound } from "./components/pageNotFound.component";
import { LayoutDefault } from "./layout/layout-default.component";
import { LayoutHome } from "./layout/layout-home.component";

export function Router() {
    return <main>
    <Routes>
      <Route path="/">
        <Route index element={<LayoutHome><Home /></LayoutHome>} />
      </Route>
      <Route path="/about-me">
        <Route index element={<LayoutDefault><AboutMe /></LayoutDefault>} />
      </Route>
      <Route path="/games">
        <Route index element={<LayoutDefault backgroundImage><Games /></LayoutDefault>} />
      </Route>
      <Route path="/web">
        <Route index element={<LayoutDefault><Web /></LayoutDefault>} />
      </Route>
      <Route path="/blog">
        <Route index element={<LayoutDefault backgroundImage><Blog /></LayoutDefault>} />
      </Route>
      <Route path="/blog/entry">
        <Route index element={<LayoutDefault><BlogEntry /></LayoutDefault>} />
      </Route>
      <Route path="/play">
        <Route index element={<LayoutDefault><Play /></LayoutDefault>} />
      </Route>
      <Route path="*" element={<LayoutDefault><PageNotFound /></LayoutDefault>} />
    </Routes>
  </main>;
}