import { useLocation } from "react-router-dom";
import { NavLink } from "./navlink.component";
import './pageNotFound.style.css';

export function PageNotFound() {
    const location = useLocation();
    return <div className="flexHorizontal flexJustifyCenter">
        <div className="flexVertical flexJustifyCenter flexGap30 height60vh">
            <h1 className="ErrorHeader">Error 404</h1>
            <h1>page "{location.pathname}" does not exist</h1>
            <NavLink to="/" name="Ok Bring me back!"/>
        </div>        
    </div>
}