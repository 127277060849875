import { PropsWithChildren } from "react";
import { Footer } from "shared/components/footer.component";
import { Header } from "shared/components/header.component";
import "./layout-default.style.css"

interface Props {
    backgroundImage?: boolean;
};

export function LayoutDefault({children, backgroundImage}: PropsWithChildren<Props>) {

    return <div className={`flexHorizontal flexJustifyCenter defaultLayoutWrapper ${backgroundImage && 'backgroundTransparency'}`}>
            {backgroundImage && <div className='contentBackground'/>}
            <div className={`defaultLayout ${backgroundImage && 'backgroundTransparency'}`}>
            <Header/>
            <div className="content">
                {children}
            </div>            
            <Footer />
        </div>
    </div>
}