export enum Engine {
    UNITY,
    PICO8,
    JS,
    LOVE2D
}

export interface Game {
    title: string,
    subtitle: string,
    release: Date,
    tags: string[],
    description: string,
    engine: Engine,
    link: string,
    thumbnail:string,
    playInWeb: boolean,
    embeddedLink?: string,
    mobileWeb?: boolean,
    linkText?: string
}

const internalGames: Game[] = [
    {
        title: "Descent from Arkovs Tower",
        subtitle: "A classical rogue like",
        release: new Date("2024-02-02"),
        tags: ["2D","pixelart", "retro", "rogue-like", "rnd"],
        description: "A classical rogue like, my biggest project yet",
        engine: Engine.LOVE2D,
        link: "https://store.steampowered.com/app/2688600/Descent_from_Arkovs_tower/",
        thumbnail:"https://img.itch.zone/aW1nLzE0MDAyMDQ5LnBuZw==/315x250%23c/d2R0Nw.png",
        playInWeb: false,
        mobileWeb: false,
        linkText: "View on Steam"
    },
    {
        title: "The white Lilly",
        subtitle: "A pixely point and click",
        release: new Date("2023-02-19"),
        tags: ["2D","jam","pixelart", "puzzle", "retro"],
        description: "A short pixely point and click puzzle game about a broken heart",
        engine: Engine.UNITY,
        link: "https://saturn91.itch.io/the-white-lilly",
        thumbnail:"https://img.itch.zone/aW1nLzExMzYzOTMxLnBuZw==/315x250%23c/NdaHB8.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/7367750?color=65b0d7",
        mobileWeb: true,
    },
    {
        title: "X-Madness",
        subtitle: "X-mas themed puzzle game",
        release: new Date("2022-12-29"),
        tags: ["2D","jam","pixelart", "puzzle", "retro", "x-mas"],
        description: "You forgot to pack all presents for christams eve, there is not much time left. Can you match all the right boxes and bows with the right toy?",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/x-madness",
        thumbnail:"https://img.itch.zone/aW1nLzEwODgyOTcyLmdpZg==/315x250%23c/cEJ3F5.gif",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/7065096?color=000000",
        mobileWeb: true,
    },
    {
        title: "Hello Roby",
        subtitle: "A programming game",
        release: new Date("2022-08-15"),
        tags: ["2D","jam","pixelart", "puzzle", "retro", "programming"],
        description: "You are an engineer who programs all the robots on the field so roby can get to the flag.",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/hello-roby",
        thumbnail:"https://img.itch.zone/aW1nLzk3OTUxMTQucG5n/315x250%23c/L%2Btb18.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/6339908?color=000000",
        mobileWeb: true,
    },
    {
        title: "A dicy boss fight",
        subtitle: "Roll the dice!!",
        release: new Date("2022-07-17"),
        tags: ["2D","jam","pixelart", "puzzle", "retro"],
        description: "Oh no! All the bosses of our past video games have decided to fight us :S Please help us defeating them!",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/a-dicy-boss-fight",
        thumbnail:"https://img.itch.zone/aW1nLzk0NzE5NDcucG5n/315x250%23c/k7eF0m.png",
        playInWeb: false,
        embeddedLink: "https://itch.io/embed-upload/6167914?color=2B2F47",
        mobileWeb: false,
    },
    {
        title: "Apocalypse Dog",
        subtitle: "A pixelart action game",
        release: new Date("2022-03-06"),
        tags: ["2D","jam","pixelart", "action"],
        description: "Apocolypse Dog is on his way to work. Those pesky dog catcher are hot on his heels.",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/apocalypse-dog",
        thumbnail:"https://img.itch.zone/aW1nLzgzMzkyNjYuZ2lm/315x250%23c/TA%2BXzu.gif",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/5380654?color=333333",
        mobileWeb: true,
    },
    {
        title: "Alpha Centauri V",
        subtitle: "Office simulator",
        release: new Date("2022-02-27"),
        tags: ["3D","jam","simulation", "story"],
        description: "You are an office worker, made for the 2022.1 Brackeys Jam",
        engine: Engine.UNITY,
        link: "https://saturn91.itch.io/alpha-centaury-v",
        thumbnail:"https://img.itch.zone/aW1nLzgyNjQwMzguanBn/315x250%23c/taRB4p.jpg",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/5339176?color=282828",
    },
    {
        title: "Run Away Train",
        subtitle: "Keep it rolling",
        release: new Date("2021-01-26"),
        tags: ["action","train","2D"],
        description: "Your train is steaming trough the endless planes of nothern america. Keep it rolling",
        engine: Engine.UNITY,
        link: "https://saturn91.itch.io/runawaytrain",
        thumbnail:"https://img.itch.zone/aW1nLzUwNTAzODcucG5n/315x250%23c/m%2F92tQ.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/3260884?color=0ecdd9",
    },
    {
        title: "Duck Island",
        subtitle: "Little Duckey goes phoenix",
        release: new Date("2020-06-11"),
        tags: ["adventure","2D", "jam", "story"],
        description: "A little duck hatches and wants to be a phoenix! Made for the 2020 I can't draw but want to make a game (again) Jam",
        engine: Engine.UNITY,
        link: "https://einsteinserbe.itch.io/duck-island",
        thumbnail:"https://img.itch.zone/aW1nLzM2MzQwNTcucG5n/315x250%23c/%2FeSDbE.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/2360992?color=e8d2bf",
    },
    {
        title: "A Dragon's Tale",
        subtitle: "Save your scaly partner",
        release: new Date("2021-12-07"),
        tags: ["action","3D", "voxel",  "jam"],
        description: "You are a dragon and have to save your partner, unleash your firy might! Made for the 2021 Manasoup Jam",
        engine: Engine.UNITY,
        link: "https://saturn91.itch.io/a-dragons-tale",
        thumbnail:"https://img.itch.zone/aW1nLzc1OTYxMjAuanBn/315x250%23c/kjjuIh.jpg",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/4921515?color=000000",
    },
    {
        title: "Devils Kitchen",
        subtitle: "Cook for the devil",
        release: new Date("2021-03-30"),
        tags: ["2D","platformer", "pixelart", "retro"],
        description: "You are one of the devils many servant and have to feed him in time. And your workshedule is litarly hell!! Can you make it trough your shift?",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/devils-kitchen",
        thumbnail:"https://img.itch.zone/aW1nLzYwODIyMzUucG5n/315x250%23c/CEuqrg.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/3915278?color=333333",
        mobileWeb: true,
    },
    {
        title: "AI Buster",
        subtitle: "Beat the AI",
        release: new Date("2020-01-05"),
        tags: ["2D","action", "pixelart", "retro", "rnd"],
        description: "You drive a tank trough a procedual generated landscape. Are you human enough to beat the AI?",
        engine: Engine.UNITY,
        link: "https://saturn91.itch.io/ai-buster",
        thumbnail:"https://img.itch.zone/aW1nLzIzOTIyNDYucG5n/original/0tqhED.png",
        playInWeb: true,
        embeddedLink: "https://saturn91.github.io/AI-Buster-Web/",
    },
    {
        title: "ANOROC",
        subtitle: "yo mama got the flue",
        release: new Date("2021-02-25"),
        tags: ["3D","jam", "tower-defense"],
        description: "Take charge over your mothers imune system to protect her spleen from the virus!",
        engine: Engine.UNITY,
        link: "https://magejoal.itch.io/anoroc-yo-mama-got-the-flu",
        thumbnail:"https://img.itch.zone/aW1nLzUyNTAzNTEucG5n/315x250%23c/7Z%2FOIJ.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/3392273?color=4993ff",
    },
    {
        title: "Blenderman",
        subtitle: "escape or stay for ever",
        release: new Date("2021-3-21"),
        tags: ["3D","jam", "horror"],
        description: "You are imprisoned in a labyrinth and want to escape... Who is that dark figure overthere?",
        engine: Engine.UNITY,
        link: "https://saturn91.itch.io/blenderman",
        thumbnail:"https://img.itch.zone/aW1nLzUzMTY5NzUucG5n/315x250%23c/1LsehM.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/3441892?color=171615",
    },
    {
        title: "Energy not inlcuded",
        subtitle: "don't touch the lava",
        release: new Date("2020-12-08"),
        tags: ["2D","jam", "platformer", "pixelart", "retro"],
        description: "Do not run out of energy! Every jump depends on it. Oh and if it is not self explanitory... don't touch the lava!",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/energy-not-included",
        thumbnail:"https://img.itch.zone/aW1nLzQ3MzkxNzcucG5n/315x250%23c/ilWl1i.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/3061272?color=4b0000",
        mobileWeb: true,
    },
    {
        title: "Satelite Catcher",
        subtitle: "explosive satelite recovery",
        release: new Date("2021-09-09"),
        tags: ["2D","action", "pixelart", "retro", "space"],
        description: "You are a space mechanics whos job it is to bring back damaged satelites. But beware of the explosions arround you.",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/satelite-catcher",
        thumbnail:"https://img.itch.zone/aW1hZ2UvMTEwOTQ1OS82NDA2MzE4LnBuZw==/250x600/t4lLsr.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/4432910?color=000000",
        mobileWeb: true,
    },
    {
        title: "Saturn91's Hangman",
        subtitle: "Definilty not hangman",
        release: new Date("2021-04-21"),
        tags: ["2D","puzzle"],
        description: "Simple hangman, programmed in html and css",
        engine: Engine.JS,
        link: "https://saturn91.itch.io/simple-html-hangman",
        thumbnail:"https://img.itch.zone/aW1nLzU2NjUyODcucG5n/315x250%23c/g8MnWK.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/3650231?color=333333",
    },
    {
        title: "The Glitcher",
        subtitle:"the wild bug hunt",
        release: new Date("2020-01-19"),
        tags: ["2D","puzzle","jam"],
        description: "Fight your way through buggy levels and destroy the source of the glitches. Use the bugs to your advantage.",
        engine: Engine.UNITY,
        link: "https://saturn91.itch.io/theglitcher",
        thumbnail:"https://img.itch.zone/aW1nLzI4ODg1NTEucG5n/315x250%23c/jxNx6S.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/1904822?color=000000",
    },
    {
        title: "The Void",
        subtitle: "A space shooter",
        release: new Date("2020-11-27"),
        tags: ["2D","space","action","retro","pixelart"],
        description: "Control your spaceship and glide trough the endless void. Fight the enemies and try to improve your highscore.",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/thevoid",
        thumbnail:"https://img.itch.zone/aW1nLzQ2NDUyMDAucG5n/315x250%23c/%2FPF3qy.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/3011328?color=100d1a",
        mobileWeb: true,
    },
    {
        title: "Traffic-Jam",
        subtitle: "Definilty not a snake clone",
        release: new Date("2021-07-08"),
        tags: ["2D","action","retro", "pixelart"],
        description: "Yes it is your typical Snake. With some dramatic music and prorgammed in Pico8.",
        engine: Engine.PICO8,
        link: "https://saturn91.itch.io/traffic-jam",
        thumbnail:"https://img.itch.zone/aW1nLzY0NTk5NzUucG5n/315x250%23c/fzB8l8.png",
        playInWeb: true,
        embeddedLink: "https://itch.io/embed-upload/4136497?color=000000",
        mobileWeb: true,
    },
];

export const games: Game[] = internalGames.sort((a, b) => b.release.getTime() - a.release.getTime());

export function filterGames(tags: string[], engines: Engine[]): Game[] {
    let outputGames: Game[] = games;

    if(engines.length > 0) {
        outputGames = outputGames.filter(game => engines.includes(Engine[game.engine] as unknown as Engine))
    }

    if (tags.length > 0) {
        outputGames = outputGames.filter(game => 
            tags.every(tag => game.tags.includes(tag))
        );
    }
    
    return outputGames;
}

export function getAllTags(games: Game[]): string[] {
    const tags: string[] = [];
    games.forEach(game => {
        game.tags.forEach(tag => {
            if(!tags.includes(tag)) tags.push(tag);
        });
    })

    return tags.sort();
}

export function getAllEngines(games: Game[]): Engine[] {
    const engines = games.map(game => Engine[game.engine] as unknown as Engine);
    const uniqueEngines = Array.from(new Set(engines));
    return uniqueEngines.sort();
}

export function getGameByTitle(title: string): Game {
    return games.filter(game => game.title === title)[0];
}
