import { GameTopic } from "./game.topic.component";
import { Topic } from "shared/components/topic.component";
import './topiclist.style.css';
import { BlogTopic } from "./blog.topic.component";

export function TopicList() {
    return <section className="padding20px flexHorizontal flexWrap flexGap50 flexJustifyCenter">
        <GameTopic/>
        <Topic title="Websites" titleLink="/web">
            <h1>Websites</h1>
            <p>no content yet...</p>
        </Topic>
        <BlogTopic/>
    </section>
}