import blogEntry01 from 'assets/blogs/01_blog.md';
import blogEntry05 from 'assets/blogs/05_blog.md';

import { useEffect } from 'react';
import { addLoadedBlog, CompiledBlog as SerializableBlog } from 'shared/redux/blog.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'shared/redux/store';

interface BlogDefinition {
    title: string;
    shortText: string;
    thumbnail?: string;
    data?: string;
    date: string;
    draft?: boolean;
}

export const rawBlogs: BlogDefinition[] = [
    {
        title: 'Wordpress?',
        shortText: 'On the search for a simple CMS system I am giving Wordpress a try. Since a while I am on the search for a way to let my Users easily define the content on their webseite. Maybe I can feed existing wordpress data into a react app?',
        thumbnail: "images/08_wordpress/WordPresslogo.svg.png",
        date: "2022-06-04",
    },
    {
        title: 'Trying out stuff :D',
        shortText: 'Just to do something relaxing and different I created a little pxielart piece using the pico8 pallet :D this might be realeated to a rumored future multiplayer game I am making with a friend, or not :D. For now enjoy a 64x64 pixel art Hotel.',
        thumbnail: "images/07_pixelArt/thumbnail.png",
        date: "2022-04-10",
    },
    {
        title: 'Creating my own engine',
        shortText: 'Since some time I am working on a javascript engine. The "Saturn91 Simple Javascript Engine" Especially was made to handle spritebased maps and making 2D Games. Lets see how that works out in the longrun.',
        thumbnail: "images/06_Saturn91SJSE/thumbnail.jpg",
        date: "2022-04-03",
    },
    {
        title: 'Working on Potion jump',
        shortText: 'Today I picked up a recent pico8 side project which I paused for some months due to a lot of other projects. I implemented a simple Textbox in which text can be displayed like in old adventure games.',
        thumbnail: 'images/potion_jump_3.gif',
        date:  "2022-03-20",
    },
    {
        title: 'E general guide to Game jams',
        shortText: 'In the last 2 years I participated in a quite a number of game jams. The latest beeing the Brackeys Game Jam 2021. As this was my 7. jam so far. And I feel pretty content with my experinces, I would like to share some insides in who I experienced those jams and also give some tipps on how YOU can have fun with game jams.',
        date:  "2022-02-28",
        data: blogEntry05,
        draft: true,
    },
    {
        title: 'Brackeys Jam -> submitted!',
        shortText: 'I did it, submited and playtested the build! What a ride. You kinda get a glimps at the journey by comparing this post thumbnail with the last\'s You can find Aplha Centuri 5 now in the game section ;-).',
        thumbnail: 'images/04_brackeys_done/thumbnail.jpg',
        date:  "2022-02-27",
    },  
    {
        title: 'Brackeys Theme was anounced',
        shortText: 'The theme for the jam was revealed today: "IT IS NOT REAL!" Well I know what is definitly real... the hype :D. So I started working on my game today by defining the gameplay and writing the first part of the story. The thumbnail of this post also shows the first visual implementation of the main Scene - an office. Stay tuned!',
        thumbnail: 'images/03_brackeys_started/thumbnail.jpg',
        date:  "2022-02-20",
    },  
    {
        title: 'Brackeys Game Jam 2022',
        shortText: 'It is time :D!! There is another Brackeys Game jam anounced. Today I prepared my Unity Project by reusing and polishing my old Unity Gamejam template. I also took off 1.5 days by the end of next week to finalize the jam. All set and ready for the theme anouncement! ',
        thumbnail: 'images/02_brackeys/brackeyJam.png',
        date:  "2022-02-18",
    },    
    {
        title: 'Time for a new Website!',
        shortText: 'After finalizing my studies in Frontend Engineering and actually starting my new position as a react frontend engineer, it is time! I could not stand the old website anymore. hastly crafted from some wild html, css and javascript templates i found in the interwebs. Today i opened a new react project and created a header and main page.',
        date:  "2022-01-29",
    },
    {
        title: 'Creating an endless Universe',
        shortText: 'Follow along as I work on "Argh there she blows", my space whaling game!. Until now the game world you could play in, was fairly small. In this Blog I will explain the basics of how I programmed a universe which will take a player 18 days straight from 0,0 to reach the border of the universe - in every direction!',
        thumbnail: "images/01_blog/ArghThereSheBlows.png",
        data: blogEntry01,
        date: "2020-04-22",
    },    
];

export interface Blog {
    title: string;
    shortText: string;
    thumbnail?: string;
    markDown?: string;
    draft?: boolean;
    date: Date;
}

export const compiledBlogs: SerializableBlog[] = [];

export function useBlogs() {

    const dispatch = useDispatch();
    const blogs = useSelector((state: RootState) => state.blog.blogs)
    
    useEffect(() => {
        if (blogs.length === 0) 
        rawBlogs.forEach((blog) => {           
            if(blog.data) {
                fetch(blog.data)
            .then((response) => response.text())
            .then((text) => {
                dispatch(addLoadedBlog({title: blog.title, shortText: blog.shortText, draft: blog.draft, thumbnail: blog.thumbnail, markDown: text, date: blog.date}));
            });
            } else {
                dispatch(addLoadedBlog({title: blog.title, shortText: blog.shortText, draft: blog.draft, thumbnail: blog.thumbnail, date: blog.date}));
            }            
        });                
    }, [blogs.length, dispatch]);

    return blogs;
}

export function compileBlog(blog: SerializableBlog): Blog {
    return {title: blog.title, shortText: blog.shortText, draft: blog.draft, thumbnail: blog.thumbnail, markDown: blog.markDown, date: new Date(blog.date)}
}

export function compileBlogs(blogs: SerializableBlog[], sortBy?: (a: Blog, b: Blog) => number): Blog[] {
    const compiledBlogs = blogs.map((blog) => compileBlog(blog));
    if(sortBy) compiledBlogs.sort(sortBy);
    return compiledBlogs;
}