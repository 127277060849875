import { Environements, useEnvironement } from "shared/hooks/use-environement";
import { AboutMeText } from "./components/about-me-classic";
import { AboutMeGame } from "./components/about-me-game/about-me-game.component";

export function AboutMe() {
    const [environement] = useEnvironement();
    
    return <div className="padding20px">
        <AboutMeText/>
        {environement === Environements.desktop && process.env.NODE_ENV === "development" && <AboutMeGame/>}
    </div>
}