import './pill-button.style.css';

interface Props {
    onClick?: Function;
    active?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
}

export function PillButton(props: Props) {
    return <button 
        tabIndex={props.disabled? -1: undefined} 
        className={`pill-button ${props.active ? "pill-button-active" : ""} ${props.disabled ? "pill-button-disabled" : ""}`} 
        onClick={() => {if(props.onClick && !props.disabled) props.onClick()}}
    >
        {props.children}
    </button>
}