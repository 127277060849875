import { PropsWithChildren } from "react";
import "./layout-home.style.css"

interface Props {};

export function LayoutHome({children}: PropsWithChildren<Props>) {

    return <div className='flexHorizontal flexJustifyCenter'>
        <div className="homeLayout">
            {children}
        </div>
    </div>
}