import { PropsWithChildren } from "react";
import "./section.style.css";

interface Props {
    title: string;
}

export function Section({title, children}: PropsWithChildren<Props>) {
    return <section className="section">
        <h1>{title}</h1>
        {children}
    </section>
}