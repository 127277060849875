import { FormEvent, useState } from "react";
import { Section } from "shared/components/section.component";
import { socialMedias } from "shared/components/social-media.component";
import "./contact.style.css";

interface FormValue {
    subject: string,
    message: string,
}

function cc(str: string) {
    let o = '';
    let c = 0;

    for (var i = 0; i < str.length; i++) {
        c = (str.charCodeAt(i)) + 2;
        o += String.fromCharCode(c);
    }
    return o;
}

export function Contact() {
    const [email, setEmail] = useState<FormValue>({
        subject: '',
        message: ''
    });

    const [emailSent, setEmailSent ]= useState(false);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        setEmailSent(true);
        window.location.href = `mailto:${cc("q_rspl7/e_kcbct>ek_gj,amk")}?subject=${email.subject}&body=${email.message.replaceAll('\n', '%0D%0A')}`;
    }

    return <Section title="Get in touch">
        <div className="contactForm">
            <form
                onSubmit={(event: FormEvent) => handleSubmit(event)}
            >
                
                <input 
                    required
                    type="text"
                    placeholder="subject..."
                    value={email.subject}
                    onChange={(event) => {
                        email.subject = event.target.value;
                        setEmail({...email});
                    }}
                />
                <textarea 
                    required
                    placeholder="Hi Saturn91..."
                    value={email.message}
                    onChange={(event) => {
                        email.message = event.target.value;
                        setEmail({...email});
                    }}
                />
                {!emailSent ? 
                    <button 
                        type="submit"
                    >
                    Send Mail
                    </button>
                    : 
                    <p className="emailSent">Thank you for getting in touch I will reach out to you :D</p>
                }
            </form>
            <div className="vertical-divider" />
            <div className="social-medias">
                {socialMedias.map(media => media)}
            </div>
        </div>
    </Section>
}