import './eyecatcher.style.css';
import logo from 'assets/icons/Saturn91_logo_round.png';
import arcadeIcon from 'assets/icons/home-page/icons8-apple-arcade.svg';
import htmlIcon from 'assets/icons/home-page/icons8-html-5.svg';
import { Environements, useEnvironement } from 'shared/hooks/use-environement';

export function Eyecatcher() {
    const [ environement ] = useEnvironement();

    return <section>
        <div className='eye-catcher-main'>
            <div className='image-parent'>
                <div />
                <div className="eye-catcher-image">
                    <div className='overlay'></div>   
                </div>   
            </div>         
            <div className="eye-catcher-title-wrapper">
                <h1 className='eye-catcher-title'>Saturn91.dev</h1>
                <div className='eye-catcher-sub-title eye-catcher-sub-title1'>
                    <img 
                        className='eye-catchersub-title-icon'
                        height="32px"
                        width="32px"
                        src={htmlIcon}
                        alt="html"
                    />
                    <h2>
                        Frontend Engineer
                    </h2>
                </div>
                <div className='eye-catcher-sub-title eye-catcher-sub-title2'>
                    <img 
                        className='eye-catchersub-title-icon'
                        height="32px"
                        width="32px"
                        src={arcadeIcon}
                        alt="joystick"
                    />
                    <h2>
                        Indie Game Dev
                    </h2>
                </div>
            </div>
            <img src={logo} alt="saturn91.dev-logo" className='heroIcon'/>  
        </div>
        {environement === Environements.mobile && <div className='baseline-mobile'></div>}      
    </section>;
}