import { Header } from "shared/components/header.component";
import { Contact } from "./components/contact.component";
import { Eyecatcher } from "./components/eyecatcher.component";
import { InfoBox } from "./components/info-box.component";
import { TopicList } from "./components/topic-list/topiclist.component";

export function Home() {
    return <>
        <Eyecatcher/>
        <Header hideHomeLink/>
        <InfoBox/>
        <TopicList/>
        <Contact/>        
    </>
}