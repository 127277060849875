import { Engine } from "../services/game.service";
import pico8Logo from "../../assets/pico8Logo.svg";
import unityLogo from "../../assets/unityLogo.svg";
import jsLogo from "../../assets/jsLogo.svg";
import love2dLogo from "../../assets/love2d.png";

interface Props {
    engine: Engine;
    width?: string;
    height?: string;
    color?: string;
}

interface EngineType {
    icon: string;
    alt: string;
    link: string;
}

export function EngineIcon(props: Props) {
    let actualEngine: EngineType = {
        icon:"",
        alt:"",
        link:""
    }

    switch(props.engine) {
        case Engine.UNITY:
            actualEngine.icon=unityLogo;
            actualEngine.alt="unity 3D";
            actualEngine.link="https://unity.com/de";
            break;
        case Engine.PICO8:
            actualEngine.icon = pico8Logo;
            actualEngine.alt="pico8";
            actualEngine.link="https://www.lexaloffle.com/pico-8.php";
            break;
        case Engine.JS:
            actualEngine.icon = jsLogo;
            actualEngine.alt="javascript";
            actualEngine.link="https://developer.mozilla.org/de/docs/Web/JavaScript";
            break;
        case Engine.LOVE2D:
            actualEngine.icon = love2dLogo;
            actualEngine.alt="love2d";
            actualEngine.link="https://love2d.org/";
            break;
    }

    return <a className="linkOnBlack" href={actualEngine.link} target="blank" rel="noopener noreferrer nofollow">
        <img src={actualEngine.icon} alt={actualEngine.alt} width={props.width} height={props.height}></img>
    </a>
}