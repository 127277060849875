import { useState } from "react";
import { Engine, filterGames } from "../shared/services/game.service";
import { GameCard } from "./components/game-card.component";
import { GameTagFilter } from "./components/game-tag-filter.component";
import "./games.style.css"

export function Games() {
    const [actualTagFilters, setActualFilter] = useState([] as string[]);
    const [actualEngineFilters, setActualEngineFilter] = useState([] as Engine[]);

    const addTag = (tag: string): void => {
        if(!actualTagFilters.includes(tag)) {
            setActualFilter([...actualTagFilters, tag]);
        } else {
            let newFilters = [...actualTagFilters];
            newFilters.splice(newFilters.indexOf(tag),1);
            setActualFilter(newFilters);
        }
    }

    const addEngine = (engine: Engine): void => {
        if(!actualEngineFilters.includes(engine)) {
            setActualEngineFilter([...actualEngineFilters, engine]);
        } else {
            let newFilters = [...actualEngineFilters];
            newFilters.splice(newFilters.indexOf(engine),1);
            setActualEngineFilter(newFilters);
        }
    }

    const filteredGames = filterGames(actualTagFilters, actualEngineFilters);

    return <div>
        <div className="background-color-page padding20px">
            <h1>My Games</h1>
            <p>As you can see I really enjoy doing game jams, this is where I shine, my games aren't pretty but I always get them running in time. My games are almost certain to not contain any game breaking bugs.</p> 
            <p>My interests are with random generation, an area I will invest more into in the future. I also have a heart for retro games which I mostly create with the lovely Pico8 engine. For more extensive game projects I used Unity for now.</p>
            <GameTagFilter
                actualTagFilters={actualTagFilters}
                setActualFilter={setActualFilter}
                setActualEngineFilter={setActualEngineFilter}
                actualEngineFilters={actualEngineFilters}
                addTag={addTag}
                addEngine={addEngine}
            />
            <p>Showing {filteredGames.length} games</p>
        </div>  
        <div className="gameGrid padding20px">
            {filteredGames.map(game => {
                return <GameCard game={game} actualFilters={actualTagFilters} setActualFilter={addTag} key={game.title}/>
            })}
        </div>           
    </div>
}