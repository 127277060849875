import { Link, useLocation } from "react-router-dom";
import './navlink.style.css';

interface NavLinkProps {
    to: string;
    name: string;
    className?: string;
}

export function NavLink(props: NavLinkProps) {
    const location = useLocation();
    return <>
        <Link 
            className= {`navlink padding20px ${location.pathname === props.to ? "link-active": ""} ${props.className}`} 
            to={props.to}
        >{props.name}</Link>
    </>
}