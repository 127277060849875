import { PillButton } from "../../shared/components/pill-button.component";
import { Engine, filterGames, games, getAllEngines, getAllTags } from "../../shared/services/game.service";

interface Props {
    actualTagFilters: string[];
    actualEngineFilters: Engine[];
    setActualFilter: Function;
    setActualEngineFilter: Function;
    addTag: Function;
    addEngine: Function;
}

export function GameTagFilter(props: Props) {
    const allTags = getAllTags(games);
    const allEngines = Object.keys(Engine).filter((engine) => isNaN(Number(engine))) as unknown as Engine[];
    
    return <div className="marginY20px">
        <h2>Made with: </h2>
        <div className="marginY20px flexHorizontal flexWrap flexGap10">
        {allEngines.map(engine => <PillButton  
                onClick={() => props.addEngine(engine)} 
                active={props.actualEngineFilters.includes(engine)} 
                disabled={!getAllEngines(filterGames(props.actualTagFilters, props.actualEngineFilters)).includes(engine)}
                key={engine}
            >
                {engine}
            </PillButton>)}
        </div>
        <h2>Tags: </h2>
        <div className="marginY20px flexHorizontal flexWrap flexGap10">
            {allTags.map(tag => <PillButton  
                onClick={() => props.addTag(tag)} 
                active={props.actualTagFilters.includes(tag)} 
                disabled={!getAllTags(filterGames(props.actualTagFilters, props.actualEngineFilters)).includes(tag)}
                key={tag}
            >
                {tag}
            </PillButton>)}

            {props.actualTagFilters.length > 0 && 
                <PillButton 
                    onClick={() => props.setActualFilter([])} 
                    active
                >
                    Reset Filter
                </PillButton>}
        </div>
    </div>
    
}