import './App.css';
import './shared/styles/colors.style.css';
import './shared/styles/layout.style.css';
import './shared/styles/text.style.css';
import './shared/styles/display.style.css';
import './shared/styles/link.style.css';
import './shared/styles/constants.style.css';
import { Router } from './shared/router';
import { ScrollToTop } from 'shared/components/scroll-top';

function App() {
  return <>
      <ScrollToTop />
      <Router />
    </>
}

export default App;
