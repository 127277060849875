import { Link } from 'react-router-dom';
import './card.style.css';

interface CardProps {
    children: React.ReactNode;
    title?: string;
    titleLink?: string;
    width?: string;
    height?: string;
}

export function Card(props: CardProps) {
    return <div className="card" style={{height: props.height, width: props.width}}>
        {(props.title || props.titleLink) && <div className='card-title'>
            {props.titleLink && <Link to={props.titleLink} className='linkOnHighLight'>{props.title}</Link>}
            {props.title && !props.titleLink && <h1 className='nonLinkTitle'>{props.title}</h1>}
        </div>}
        <div className='card-content flexVertical'>
            {props.children}
        </div>        
    </div>
}