import { Card } from "shared/components/card.component";

interface Props {
    title: string;
    titleLink?: string;
    children: React.ReactNode;
    height?: string;
}

export function Topic(props: Props) {
    return <Card title={props.title} titleLink={props.titleLink} width="300px" height={props.height ? props.height : "400px"}>
        <div className="padding10px">
            {props.children}
        </div>        
    </Card>
}
