import { Link } from "react-router-dom";
import { EngineIcon } from "shared/components/engineIcon";
import { Environements, useEnvironement } from "shared/hooks/use-environement";
import { Card } from "../../shared/components/card.component";
import { PillButton } from "../../shared/components/pill-button.component";
import { Game } from "../../shared/services/game.service";
import './game-card.style.css';

interface Props {
    game: Game;
    actualFilters: string[];
    setActualFilter: Function;
}

interface GameThumbnailProps {
    game: Game;
}

function GameThumbnail({game}: GameThumbnailProps) {
    const [environment] = useEnvironement();

    return <><img src={game.thumbnail} alt={`thumbnail of${game.thumbnail}`} className="thumbnail" width="100%"/>
    {environment ===  Environements.desktop && <div className="gameInfo">
        <h3 style={{color: "var(--text-color", marginBottom: 0, marginTop: 0, fontSize: "small"}}>{game.title}{game.subtitle && ` - ${game.subtitle}`}</h3>
        <p>
            {game.description}
        </p>   
        <div className="flexHorizontal flexAllignCenter madeByText">
            <p style={{marginRight: "4px", minWidth: "120px"}}>made with</p><EngineIcon engine={game.engine} height="16px" color="white"></EngineIcon>
        </div>                    
        <div className="flexHorizontal flexAllignCenter">
            <p style={{marginRight: "4px", minWidth: "120px"}}>released: </p><p>{game.release.toLocaleDateString()}</p>
        </div>  
    </div>}</>
}

export function GameCard({game, actualFilters, setActualFilter}: Props) {
    const [environment] = useEnvironement();
    return <div className="game-card"> 
        <Card>
            <div className="game-card-container">
                {environment === (Environements.desktop || game.mobileWeb) && game.playInWeb? 
                        
                    <Link className="thumbnailLink" to={`/play?game=${game.title}`}>
                        <GameThumbnail game={game}/>
                    </Link> :
                    <a className="thumbnailLink" href={game.link} target="_blank" rel="noopener noreferrer nofollow">
                        <GameThumbnail game={game}/>
                    </a>
                }
                <div className="padding10px details">
                    <h2>{game.title}</h2>             
                    <div className="flexHorizontal flexWrap flexGap10 mt20px">
                        {game.tags.sort().map(tag => <PillButton active={actualFilters.includes(tag)} onClick={() => setActualFilter(tag)} key={"tag_" + tag}>{tag}</PillButton>)}
                    </div>                       
                </div>
                <a href={game.link} target="_blank" className="linkOnBlack padding10px displayInlineBlock itchLink" rel="noreferrer">{game.linkText || "View on Itch.io"}</a>
                {environment ===  Environements.mobile && game.mobileWeb && <Link className="mobilePlayBtn" to={`/play?game=${game.title}`}>Play in web (mobile)</Link>} 
            </div>        
        </Card> 
    </div>
}