import { useState } from "react";
import { Topic } from "shared/components/topic.component";
import { compileBlogs, useBlogs } from "shared/services/blog.service";
import "./topic.style.css";
import { HorizontalSelector } from "./horizontal.selector.component";

export function BlogTopic() {
    const blogs = compileBlogs(useBlogs(), (a, b) => b.date.getTime() - a.date.getTime()).filter(blog => !blog.draft || process.env.NODE_ENV === "development");
    const [actualBlog, setBlog] = useState(0);

    return <Topic title="Blog" titleLink="/blog">        
            {blogs.length && <div className="topicContentContainer flexVertical flexAllignCenter flexJustifyCenter">
                {blogs[actualBlog].thumbnail && <div className="flexHorizontal flexJustifyCenter mb10px">
                    <img 
                        className="thumbnail"
                        src={blogs[actualBlog].thumbnail} 
                        alt={blogs[actualBlog].title}
                    ></img>
                </div>}  
                <h1>{blogs[actualBlog].title}</h1>
                <p>{blogs[actualBlog].shortText}</p>
            </div>}        
            <HorizontalSelector objects={blogs} setActualObject={setBlog} />    
    </Topic>
}
