/**
 * As suggested by "Saeed Rohani" on https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
 * This component will always scroll to the top of the page on page load...
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);

    return (null);
}