import { useQuery } from "../shared/hooks/use-query"
import { getGameByTitle } from "../shared/services/game.service";

export function Play() {
    const gameQueryParameter = useQuery().get('game');
    const gameTitle = gameQueryParameter? gameQueryParameter: '';
    const game = getGameByTitle(gameTitle);

    return <div className="flexHorizontal flexJustifyCenter flexAllignCenter height80vh">
        <iframe 
            title={gameTitle}            
            src={game.embeddedLink} 
            width="90%"
            height="80%"
            allowFullScreen={true}
            >
                <a href={game.link}>Play {game.title} on itch.io</a>
            </iframe>
    </div>
}