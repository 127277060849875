import githubLogo from "../../assets/icons/social-media/icons8-github.svg";
import pico8Logo from "../../assets/pico8Logo.svg";
import twitterLogo from "../../assets/icons/social-media/icons8-twitter.svg";
import youtubeLogo from "../../assets/icons/social-media/icons8-youtube-play.svg";
import itchIoLogo from "../../assets/icons/social-media/icons8-itch-io.svg";
import "./social-media.style.css";

interface Props {
    title: string;
    link: string;
    icon: string;
    width?: string;
    height?: string;
}

const SocialMedia = ({title, link, icon, width="32px", height="32px"}: Props) => {
    return <a className="linkOnBlack flexHorizontal flexAllignCenter height50 social-media-link" href={link} target="blank" rel="noopener noreferrer nofollow">
        <img src={icon} alt={link} width={width} height={height} className="mr32px"></img>
        {title}
    </a>
}

export const socialMedias = [
    <SocialMedia title={"github"} link={"https://github.com/Saturn91"} icon={githubLogo} />,
    <SocialMedia title={"itch.io"} link={"https://saturn91.itch.io/"} icon={itchIoLogo} />,
    <SocialMedia title={"lexaloffle.com"} link={"https://www.lexaloffle.com/bbs/?uid=48932"} icon={pico8Logo} />,
    <SocialMedia title={"youtube"} link={"https://www.youtube.com/channel/UCoJoZTzfopmU-sHY6D5ljeA"} icon={youtubeLogo} />,
    <SocialMedia title={"twitter"} link={"https://twitter.com/Saturn9110"} icon={twitterLogo} />,
];
