import "./footer.style.css";
import logo from "assets/Logo.png";

export function Footer() {
    return <footer className="flexVertical flexJustifyCenter">
        <div className="flexHorizontal flexJustifySpaceBetween flexAllignCenter">
            <img src={logo} alt="Saturn91.dev" height="40px"/>
            <p>&copy; saturn91.dev</p>
        </div>        
    </footer>
}