import { useState } from "react";

interface Props<T> {
    objects: T[];
    setActualObject: (index: number) => void
}

export function HorizontalSelector<T>(props: Props<T>) {
    const [actualState, setActual] = useState(0);

    const update = (actualIndex: number) => {
        setActual(actualIndex);
        props.setActualObject(actualIndex);
    }

    const increaseActual = (positiv: boolean): void => {
        if (positiv) {
            if (actualState + 1 < props.objects.length) {
                update(actualState + 1);
                return;
            }
            update(0);
        } else {
            if (actualState - 1 >= 0) {
                update(actualState - 1);
                return;
            }
            update(props.objects.length -1);
        }
    }

    return <div className="flexHorizontal flexJustifyCenter flexAllignBaseLine">
        <button 
            onClick={() => increaseActual(false)}
            className="selectProjBtn projectListInteractionText"
        >{"<"}</button>
        <p className="projectListInteractionText">{(actualState+1)} / {props.objects.length}</p>
        <button 
            onClick={() => increaseActual(true)}
            className="selectProjBtn projectListInteractionText"
        >{">"}</button>
    </div>
}