import "../../shared/styles/link.style.css";
import "./about-me-classic.style.css"

export function AboutMeText() {
    return <>
        <h1>About me</h1>
        <div className="paragraph">
            <p style={{marginTop: 16, marginBottom: 16}}>Since january 2022 I am a proffessional Web Developer working as a Junior Frontend Engineer for the swiss company <a className="linkOnBlack" href="https://www.jobcloud.ch/c/en/about/">Jobcloud AG</a>.
                Here I mainly work with react.
            </p>
            <p>During my spare time I programm little websites as side projects to try out new things. Further more I have a great interest in Game development especially random generations and old retro 2D games. 
                For Game developement I use Unity 3D, Pico8 or native web technologies.
            </p>
        </div>
        
    </>
}