import { compileBlogs, useBlogs } from 'shared/services/blog.service';
import { BlogBanner } from './components/blog.banner';

export function Blog() {
    const blogs = compileBlogs(useBlogs(), (a, b) => b.date.getTime() - a.date.getTime());
    return <div className="padding20px">
        <div className="flexHorizontal flexGap30 flexJustifyCenter flexWrap">
            
            {blogs.map((blog, index) => {
                if (!blog.draft || process.env.NODE_ENV === "development")
                return <BlogBanner blog={blog} index={index} key={`blog_${blog.title}`}/>
                return <></>
            })}
        </div>
    </div>
}